import React, { createContext, useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { SessionProviderLogMessages } from 'helpers/constants/crossTabSyncConstatns';
import { generateUniqueTabId } from 'helpers/utils/generateTabId';
import { Account } from 'shared/types/account';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import { useAccountDataFetcher } from 'frontastic/hooks/useAccount/useAccountDataFetcher';
import { sdk } from 'sdk';
import initCrossTabSync from './initCrossTabSync';
import initSessionEventListeners from './initSessionEventListeners';

export type LoggedInState = {
  isLoggedIn: boolean;
  account: Account;
  loading: boolean;
  error: any;
};

type SessionContextValue = {
  loggedInState: LoggedInState;
  setLoggedInState: React.Dispatch<React.SetStateAction<LoggedInState>>;
};

const SessionContext = createContext<SessionContextValue>({} as SessionContextValue);

export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const { account, loggedIn, accountLoading, error } = useAccountDataFetcher();
  const { isFeatureEnabled } = useFeatureFlags();
  const isFeatureDataDogErrorTrackingEnabled = isFeatureEnabled(FEATURE_FLAG_LIST.DATA_DOG_ERROR_TRACKING);

  const [loggedInState, setLoggedInState] = useState<LoggedInState>({
    isLoggedIn: false,
    account: {} as Account,
    loading: true,
    error: null,
  });

  const router = useRouter();

  useEffect(() => {
    if (loggedInState?.isLoggedIn && account === undefined) {
      router.push('/session-timeout');
    }
    setLoggedInState({
      isLoggedIn: loggedIn,
      account: account || ({} as Account),
      loading: accountLoading,
      error,
    });

    // set partnerKey in SDK for Datadog error rum event logging
    const partnerKey = account?.companyProfile?.companyId;
    sdk.setPartnerKey(partnerKey);
  }, [account, loggedIn, accountLoading, error]);

  useEffect(() => {
    // setting for feature flag enabling/disabling
    sdk.setFeatureFlag(isFeatureDataDogErrorTrackingEnabled);
  }, [isFeatureDataDogErrorTrackingEnabled]);

  useEffect(() => {
    const newTabId = generateUniqueTabId();
    initSessionEventListeners(setLoggedInState, newTabId);
    initCrossTabSync(setLoggedInState, router, newTabId);
  }, []);

  return <SessionContext.Provider value={{ loggedInState, setLoggedInState }}>{children}</SessionContext.Provider>;
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error(SessionProviderLogMessages.SESSION_PROVIDER_ERROR);
  }
  return context;
};

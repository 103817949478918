import { createContext, useContext, useState, PropsWithChildren, Dispatch, SetStateAction } from 'react';
import { DataProps } from 'shared/types/nav';

type StudioDataContextProps = {
  data: DataProps;
  setData: Dispatch<SetStateAction<DataProps>>;
};

const StudioDataContext = createContext<StudioDataContextProps | undefined>(undefined);

// Ignoring eslint rule because PropsWithChildren<Record<string, never>> throws error
// it expects Element instead of never
/* eslint-disable-next-line */
const StudioDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const [data, setData] = useState<DataProps>({});

  const value = {
    data,
    setData,
  };
  return <StudioDataContext.Provider value={value}>{children}</StudioDataContext.Provider>;
};

export const useStudioDataContext = (): StudioDataContextProps => {
  const context = useContext(StudioDataContext);
  if (context === undefined) {
    throw new Error('useStudioDataContext must be used within a StudioDataProvider');
  }
  return context;
};

export { StudioDataContext, StudioDataProvider };

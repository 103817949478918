export enum CommunicationChannel {
  AUTH_CHANNEL = 'auth-channel',
}

export enum SessionEvent {
  USER_LOGGED_OUT = 'userLoggedOut',
  USER_LOGGED_IN = 'userLoggedIn',
  ERROR_CAUGHT = 'errorCaught',
}

export enum CrossTabLogs {
  BROADCAST_CHANNEL_UNSUPPORTED = 'BroadcastChannel API is not supported in this environment.',
  BROADCAST_CHANNEL_INIT_FAILED = 'Failed to initialize BroadcastChannel.',
  UNKNOWN_EVENT = 'Unknown session event received:',
  ERROR_PROCESSING_EVENT = 'Error processing cross-tab sync message.',
}

export enum SessionEventLogMessages {
  BROADCAST_CHANNEL_UNSUPPORTED = 'BroadcastChannel API is not supported in this environment.',
  BROADCAST_CHANNEL_INIT_FAILED = 'Failed to initialize BroadcastChannel.',
  INVALID_ACCOUNT_DATA = 'Invalid account data in USER_LOGGED_IN event.',
}

export enum SessionProviderLogMessages {
  SESSION_PROVIDER_ERROR = 'useSession must be used within a SessionProvider',
}

import { Guid } from './guid';

export const getRetryServerOptions = {
  serverOptions: {
    req: {
      method: 'POST',
      headers: {
        'coFE-Custom-Configuration': JSON.stringify({ 'x-lll-fe-retry-correlation-id': `${Guid.newGuid(true)}` }),
      },
    } as any,
  },
};

export const removeApplicationData = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.slice(0, eqPos).trim() : cookie.trim();

    document.cookie = name + '=;expires=' + new Date(0).toUTCString() + ';path=/';
  }

  localStorage.clear();
  sessionStorage.clear();
};

export const parseJwt = (token: string) => {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
};

export const getTokenData = (token: string) => {
  try {
    const clientData = parseJwt(token)?.ctx?.[0]?.data ?? {};
    return {
      clientLibraryIntegrity: clientData.clientLibraryIntegrity,
      clientLibrary: clientData.clientLibrary,
    };
  } catch (error) {
    console.error('Error parsing JWT to get clientLibrary:', error);
    return { clientLibraryIntegrity: '', clientLibrary: '' };
  }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Logger {
    constructor() {
        // TODO: add more keywords here as needed
        this.blockList = [
            "email",
            "password",
            "firstName",
            "lastName",
            "phoneNumber",
            "addressLine1",
            "addressLine2",
            "clientAddress",
        ];
    }
    maskMessage(message) {
        let maskedMessage = message;
        this.blockList.forEach((keyword) => {
            const regex = new RegExp(`(${keyword})([^,]*)`, "gi");
            maskedMessage = maskedMessage.replace(regex, (match, p1, p2) => {
                return p1 + "*".repeat(p2.length);
            });
        });
        return maskedMessage;
    }
    static debug(message) {
        const maskedMessage = Logger.instance.maskMessage(message);
        console.debug(`Debug: ${maskedMessage}`);
    }
    static info(message) {
        const maskedMessage = Logger.instance.maskMessage(message);
        console.info(`Info: ${maskedMessage}`);
    }
    static warn(message) {
        const maskedMessage = Logger.instance.maskMessage(message);
        console.warn(`Warn: ${maskedMessage}`);
    }
    static error(message) {
        const maskedMessage = Logger.instance.maskMessage(message);
        console.error(`Error: ${maskedMessage}`);
    }
}
Logger.instance = new Logger();
exports.default = Logger;

import { Account } from 'shared/types/account';
import { sdk } from 'sdk';
import Logger from 'helpers/logger';
import { mutate } from 'swr';
import { LoggedInState } from '.';
import { CommunicationChannel, SessionEventLogMessages, SessionEvent } from 'helpers/constants/crossTabSyncConstatns';
import { parseNGCError } from 'helpers/ngc';

const initSessionEventListeners = (setLoggedInState: (state: LoggedInState) => void, tabId: string) => {
  let channel: BroadcastChannel | null = null;

  try {
    if (typeof BroadcastChannel !== 'undefined') {
      channel = new BroadcastChannel(CommunicationChannel.AUTH_CHANNEL);
    } else {
      Logger.warn(SessionEventLogMessages.BROADCAST_CHANNEL_UNSUPPORTED);
    }
  } catch (error) {
    Logger.error(`${SessionEventLogMessages.BROADCAST_CHANNEL_INIT_FAILED} ${JSON.stringify(error)}`);
  }

  sdk.on(SessionEvent.ERROR_CAUGHT, (event) => {
    const error = parseNGCError(event.data.error);
    if (error?.code == '400401') {
      setLoggedInState({ isLoggedIn: false, account: {} as Account, loading: false, error: error });
      if (channel) {
        channel.postMessage({ event: SessionEvent.USER_LOGGED_OUT, account: {} as Account });
      }
    }
  });
  sdk.on(SessionEvent.USER_LOGGED_OUT, () => {
    mutate('/action/account/getAccount');
    setLoggedInState({ isLoggedIn: false, account: {} as Account, loading: false, error: undefined });

    if (channel) {
      channel.postMessage({ event: SessionEvent.USER_LOGGED_OUT, account: {} as Account, originTabId: tabId });
    }
  });

  sdk.on(SessionEvent.USER_LOGGED_IN, (event) => {
    const account = event?.data?.account as Account;
    if (account) {
      setLoggedInState({ isLoggedIn: true, account, loading: false, error: undefined });
      mutate('/action/account/getAccount');

      if (channel) {
        channel.postMessage({ event: SessionEvent.USER_LOGGED_IN, account, originTabId: tabId });
      }
    } else {
      Logger.warn(`${SessionEventLogMessages.INVALID_ACCOUNT_DATA} ${JSON.stringify(event)}`);
    }
  });

  return () => {
    if (channel) {
      channel.close();
    }
  };
};

export default initSessionEventListeners;

import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { FEATURE_FLAG_LIST_RUNTIME, FEATURE_FLAG_LIST_STATIC } from 'shared/utils/constants';
import { FeatureFlag } from 'shared/types/FeatureFlags';
import useSWR from 'swr';
import { sdk } from 'sdk';

type FeatureFlagReturnValue = {
  featureFlagsList: FeatureFlag[];
  isFeatureEnabled: (featureFlag: string, pageData?: any) => boolean | undefined;
  setFeatureFlagList: (featureFlag: FeatureFlag[]) => void;
  isFeatureFlagDataAvailable: boolean;
};
type FeatureFlagResponse = {
  isError: boolean;
  data: FeatureFlag[];
};

let FEATURE_FLAG_LIST_DYNAMIC: typeof FEATURE_FLAG_LIST_STATIC | typeof FEATURE_FLAG_LIST_RUNTIME =
  FEATURE_FLAG_LIST_STATIC;

let featureFlagStore: { [key: string]: boolean } = {};

export const useFeatureFlags = (): FeatureFlagReturnValue => {
  const [featureFlagsList, setFeatureFlagList] = useState<FeatureFlag[]>([]);
  const [isFeatureFlagDataAvailable, setIsFeatureFlagDataAvailable] = useState(false);
  const [initialized, setInitialized] = useState(false); // Ensure initialization
  const result = useSWR('/action/account/getFeatureFlagList', async () => {
    const res = await sdk.callAction<FeatureFlagResponse>({
      actionName: 'account/getFeatureFlagList',
    });
    return res.isError ? ({ isError: true, data: [] } as FeatureFlagResponse) : res;
  });

  useEffect(() => {
    if (!result?.data?.isError) {
      const featureFlags = result?.data?.data as FeatureFlag[];
      if (!isEqual(featureFlags, featureFlagsList) && featureFlags) {
        setFeatureFlagList(featureFlags);
        setIsFeatureFlagDataAvailable(true);

        featureFlagStore = featureFlags.reduce((acc, flag) => {
          acc[flag.name] = flag.isEnabled;
          return acc;
        }, {} as { [key: string]: boolean });
      }
    }
  }, [result]);

  useEffect(() => {
    if (!initialized && featureFlagsList.length > 0) {
      const flag = featureFlagsList.find(
        (savedFeature) => savedFeature.name === FEATURE_FLAG_LIST_DYNAMIC.ENABLE_COMMON_MODULES,
      );

      const isCommonModuleActive = flag?.isEnabled ?? false;

      if (isCommonModuleActive !== undefined) {
        FEATURE_FLAG_LIST_DYNAMIC = isCommonModuleActive ? FEATURE_FLAG_LIST_RUNTIME : FEATURE_FLAG_LIST_STATIC;
      }

      setInitialized(true);
    }
  }, [featureFlagsList, initialized]);

  const getPageFeatureFlags = (pageData: any) => {
    return pageData?.data?.data?.dataSource?.featureFlags || [];
  };

  const isFeatureEnabled = (feature: string, pageData: any) => {
    const flagsList = featureFlagsList.length > 0 ? featureFlagsList : getPageFeatureFlags(pageData);
    if (flagsList.length > 0) {
      if (!isEqual(flagsList, featureFlagsList)) {
        setFeatureFlagList(flagsList);
      }
    }
    const flag: FeatureFlag | undefined = flagsList?.find((savedFeature: FeatureFlag) => savedFeature.name == feature);
    return flag && flag.isEnabled;
  };

  return {
    featureFlagsList,
    isFeatureEnabled,
    setFeatureFlagList,
    isFeatureFlagDataAvailable,
  };
};

export function getFeatureFlag(flag: string): boolean {
  return featureFlagStore[flag] ?? false;
}

export const featureFlagProxy: { [key: string]: boolean } = new Proxy(
  {},
  {
    get(_, prop: string) {
      return featureFlagStore[prop as string] ?? false;
    },
  },
);

export { FEATURE_FLAG_LIST_DYNAMIC as FEATURE_FLAG_LIST };

'use client';

import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { appWithTranslation } from 'next-i18next';
import { SWRConfig } from 'swr';
import '@lululemon/ecom-pattern-library/lib/styles/base.css';
import Toaster from 'components/commercetools-ui/atoms/toaster';
import { CartProvider, StudioDataProvider, LoadingOverlayProvider } from 'context';
import { buildGAPayload } from 'helpers/utils/buildGAPayload';
import { useFeatureFlags, FEATURE_FLAG_LIST } from 'hooks';
import { useAnalytics } from 'hooks/useAnalytics';
import { sdk } from 'sdk';
import 'tailwindcss/tailwind.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/app.css';
import '../styles/app.scss';
import { SessionProvider } from 'context/session';

const ddconfig = {
  applicationId: process.env.DDOG_APP_ID || '',
  clientToken: process.env.DDOG_CLIENT_TOKEN || '',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'b2b_ecomm',
  env: process.env.FRONTASTIC_ENV || 'dev',
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
  beforeSend: (event: any, context: any) => {
    // collect a RUM resource's response headers
    if ((event.type === 'resource' || event.resource?.type === 'fetch') && context.response) {
      event.context.responseHeaders = Object.fromEntries(context.response.headers);
    }
    return true;
  },
};

datadogRum.init(ddconfig);

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  sdk.configureForNext(router.locale as string);
  const { isFeatureEnabled } = useFeatureFlags();
  const isDatadogTagsActive = isFeatureEnabled(FEATURE_FLAG_LIST.DATADOG_TAGS);

  // Not supposed to call page-view trackEvent for below urls
  // '*/cart',  '*/checkout', '*/p/*', '*limit=*',

  const regexForPageViewUrls = /\/cart$|\/checkout$|\/p\/[^\/]+|limit=[^&]+|\/thank-you\?/;

  const shouldCallPayload = (): boolean => {
    const currentPathForUrl = window.location.href;
    const hasKeyword = !regexForPageViewUrls.test(currentPathForUrl);
    return hasKeyword;
  };

  useEffect(() => {
    const handleRouteChange = () => {
      const urlParams = new URLSearchParams(window.location.search);

      const eventCategory = EVENT_CATEGORY.PAGE_VIEW;

      const payload = buildGAPayload(eventCategory, urlParams);

      if (shouldCallPayload()) {
        trackEvent(eventCategory, payload);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    // once the feature flag is removed, we can take it out from the component and can put right after the initilization
    if (isDatadogTagsActive) {
      datadogRum.setGlobalContext({
        lll_app: 'b2b-ecomm-ui',
        lll_owner: 'b2b-ecomm',
        lll_team: 'b2b-ecomm',
      });
    }
  }, [isDatadogTagsActive]);

  const cartProviderAndChildren = (
    <CartProvider>
      <LoadingOverlayProvider>
        <Component {...pageProps} />
        <Toaster />
      </LoadingOverlayProvider>
    </CartProvider>
  );

  return (
    <SWRConfig
      value={{
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
      }}
    >
      <StudioDataProvider>
        <SessionProvider {...pageProps}>{cartProviderAndChildren}</SessionProvider>
      </StudioDataProvider>
    </SWRConfig>
  );
}

export default appWithTranslation(App);

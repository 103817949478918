const FEATURE_FLAG_LIST_STATIC = {
  ACCOUNT: "isAccountActive",
  HOME_PAGE: "isHomePageActive",
  CHECKOUT: "isCheckoutActive",
  PDP: "isPDPActive",
  CDP: "isCDPActive",
  CART: "isCartActive",
  ORDER_SCROLL_BAR_FIX: "isOrderScrollBarFixActive",
  ORDER_DETAILS_CSV: "isOrderDetailsCSVActive",
  FORMIK_FORGOT_PASSWORD: "isFormikForgotPasswordActive",
  WHATS_NEW_CACHING: "isWhatsNewCachingActive",
  QUICK_LINKS: "isFeature_EAOEUF8_Active",
  PROMOTION_CAROUSEL: "isFeature_EAOEUF7_Active",
  SHOW_HASHED_USER_CREDS_IN_LOGS: "showHashedUserCredsInLogs",
  IS_SPLIT_NGC_CALLS_GETSHIPPINGMETHIDS_FLAG: "isFeature_B2BSWAT15_Active",
  PROMOCODE_TIME_OUT_FIX: "isFeature_B2BSWAT2_Active",
  POST_FILTER_CHANGE_FIX: "isFeature_EAOEUF341_Active",
  UNDER_MAINTENANCE: "isFeature_EAOEUF274_Active",
  PRODUCT_TESTING: "isProductTestingActive",
  FORMIK_RESET_PASSWORD: "isFormikResetPasswordActive",
  LVP_CHANGES: "isLVPChangesActive",
  NAV_BY_ACTIVITY: "isNavByActivityActive",
  DATADOG_TAGS: "isDatadogTagsActive",
  ORDER_REFERENCE_TEXT_CHANGE: "isOrderRefrenceIdTextChangeActive",
  OPTIMIZATION_UPDATE_SHIPPING_METHOD: "isOptimization_EAOELF607_Active",
  BILLINGID_FIX_FOR_AR_PAYMENT_METHOD: "isBugFix_EAOELF681_Active",
  RETRY_UPDATE_PAYMENT_METHOD: "isBugFix_EAOELF652_Active",
  EDIT_CART_ITEM_FIX: "isBugFix_EAOEUF242_Active",
  SHIPPING_ADDRESS_RELOAD_FIX: "isBugFix_EAOELF715_Active", // need to remove as part of EAOELF-775 story
  SWR_REVALIDATION_FIX_FOR_BILLINGIDS: "isBugFix_EAOELF713_Active",
  ENABLE_COMMON_MODULES: "isFeature_EAOEUF459_Active",
  AR_BILLING_ADDRESS_INTERMITTENT_FIX: "isBugFix_EAOELF720_Active",
  CYBERSOURCE_VERSION_UPGRADE: "isFeature_EAOELF685_Active",
  ALLOW_DELETE_ONLY_CREDIT_CARD: "isFeature_EAOELF474_Active",
  GET_SHIPPING_METHOD_FIX: "isBugFix_EAOELF723_Active",
  TERMS_OF_SALE_PROGRAM_TYPES: "isFeature_TOSProgramTypes_EAOELF245_Active",
  IS_EAOEUF239_ACTIVE: "isFeature_EAOEUF239_Active",
  DATA_DOG_ERROR_TRACKING: "isFeature_DataDogErrorTracking_EAOELF902_Active",
  IS_EAOELF782_ACTIVE: "isFeature_EAOELF782_Active",
  ENABLE_LOADER_FOR_NAV_BY_ACTIVITY: "isBugFix_EAOEUF779_Active",
};

const FEATURE_FLAG_LIST_RUNTIME = Object.assign(
  {
    TEST_KEY: "TEST_VALUE",
  },
  FEATURE_FLAG_LIST_STATIC
);

const StatusCode_STATIC = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  BAD_REQUEST: 400,
  SUCCESS: 200,
  NOT_FOUND: 404,
  VERSION_MISMATCH: 409,
  TIMEOUT: 599,
  TOKEN_EXPIRED: 410,
};

const StatusCode_RUNTIME = Object.assign({}, StatusCode_STATIC);

const VariantAttributes_STATIC = {
  ACTIVE: "variants.attributes.active",
  ACTIVITY: "variants.attributes.activity",
  CANADA_END_DATE: "variants.attributes.canEndDate",
  CANADA_START_DATE: "variants.attributes.canStartDate",
  COLOR_CODE: "variants.attributes.colourCode",
  COLOR_DESCRIPTION: "variants.attributes.colourDescription",
  COLOR_FAMILIES: "variants.attributes.colourFamilies",
  FRANCHISE: "variants.attributes.franchise",
  GENDER: "variants.attributes.gender",
  INSEAM: "variants.attributes.inseam",
  INSEAM_GROUP_ID: "variants.attributes.inseamGroupId",
  SIZE: "variants.attributes.size",
  STYLE_ID: "variants.attributes.styleId",
  US_END_DATE: "variants.attributes.usEndDate",
  US_START_DATE: "variants.attributes.usStartDate",
};

const VariantAttributes_RUNTIME = Object.assign({}, VariantAttributes_STATIC);

export {
  FEATURE_FLAG_LIST_STATIC,
  FEATURE_FLAG_LIST_RUNTIME,
  StatusCode_STATIC,
  StatusCode_RUNTIME,
  VariantAttributes_STATIC,
  VariantAttributes_RUNTIME,
};

import Logger from 'helpers/logger';
import { LoggedInState } from '.';
import { Account } from 'shared/types/account';
import { NextRouter } from 'next/router';
import { CommunicationChannel, CrossTabLogs, SessionEvent } from 'helpers/constants/crossTabSyncConstatns';

const initCrossTabSync = (setLoggedInState: (state: LoggedInState) => void, router: NextRouter, tabId: string) => {
  let channel: BroadcastChannel | null = null;

  try {
    if (typeof BroadcastChannel !== 'undefined') {
      channel = new BroadcastChannel(CommunicationChannel.AUTH_CHANNEL);
    } else {
      Logger.warn(CrossTabLogs.BROADCAST_CHANNEL_UNSUPPORTED);
      return;
    }
  } catch (error) {
    Logger.error(`${CrossTabLogs.BROADCAST_CHANNEL_INIT_FAILED} ${JSON.stringify(error)}`);
    return;
  }

  channel.onmessage = (event) => {
    try {
      const { event: sessionEvent, account, originTabId } = event.data;

      switch (sessionEvent) {
        case SessionEvent.USER_LOGGED_OUT:
          setLoggedInState({ isLoggedIn: false, account: {} as Account, loading: false, error: undefined });

          if (originTabId !== tabId) {
            router.push('/login');
          }

          break;

        case SessionEvent.USER_LOGGED_IN:
          setLoggedInState({ isLoggedIn: true, account, loading: false, error: undefined });
          break;

        default:
          Logger.warn(`${CrossTabLogs.UNKNOWN_EVENT} ${JSON.stringify(sessionEvent)}`);
      }
    } catch (error) {
      Logger.error(`${CrossTabLogs.ERROR_PROCESSING_EVENT} ${JSON.stringify(error)}`);
    }
  };

  return () => {
    if (channel) {
      channel.close();
    }
  };
};

export default initCrossTabSync;

import { ComposableCommerce, ComposableCommerceEvents } from '@commercetools/frontend-composable-commerce';
import { SDK } from '@commercetools/frontend-sdk';
import { datadogRum } from '@datadog/browser-rum';
import Logger from 'helpers/logger';
import { useAnalytics } from 'hooks/useAnalytics';
import { getLocalizationInfo } from 'project.config';

// Add other extension's custom events to the SDK's generic type here,
// by extending ComposableCommerceEvents with their type with an
// intersection. For example <ComposableCommerceEvents & OtherEvents>.
class CommercetoolsSDK extends SDK<ComposableCommerceEvents> {
  composableCommerce!: ComposableCommerce;
  private partnerKey?: string | undefined;
  private isFeatureDataDogErrorTrackingEnabled?: boolean | undefined;
  // Add your other extensions here.
  constructor() {
    super();
    // eslint-disable-next-line
    const { trackEvent, EVENT_CATEGORY } = useAnalytics();
    this.composableCommerce = new ComposableCommerce(this);
    // Initialise your other extensions here.
    this.on('errorCaught', (event) => {
      // Globally handle any errors caught by the SDK from your
      // extensions. Log error, fire notification etc...
      if (event.eventName !== 'errorCaught') {
        trackEvent(EVENT_CATEGORY.ERROR, {
          error: {
            errorType: event.eventName,
            errorDetails: JSON.stringify(event.data.error),
            errorGuestFacing: true,
            errorMessage: JSON.stringify(event.data.error),
          },
        });
      }
      if (this.isFeatureDataDogErrorTrackingEnabled && event.eventName === 'errorCaught') {
        const { error, frontasticRequestId } = event.data;
        const { message, actionName } = error || {};

        datadogRum.addError(actionName, {
          error: message ?? 'No Message',
          errorAction: actionName ?? 'Missing',
          correlationId: frontasticRequestId,
          partnerKey: this.partnerKey,
        });
      }
      Logger.error(`SDK error: ${JSON.stringify(event.data)}`);
    });
  }

  setPartnerKey(partnerKey: string | undefined) {
    this.partnerKey = partnerKey;
  }

  setFeatureFlag(isFeatureDataDogErrorTrackingEnabled: boolean | undefined) {
    this.isFeatureDataDogErrorTrackingEnabled = isFeatureDataDogErrorTrackingEnabled;
  }

  configureForNext(nextJsLocale: string) {
    const { locale, currency } = getLocalizationInfo(nextJsLocale);

    sdk.configure({
      locale,
      currency,
      extensionVersion: process.env.NEXT_PUBLIC_EXT_BUILD_ID ?? 'dev',
      endpoint: (process.env.NEXT_PUBLIC_FRONTASTIC_HOST as string).split('/frontastic')[0],
    });
  }
}
// Create a single instance of the sdk.
const sdk = new CommercetoolsSDK();
// Export only the instance to serve as a singleton throughout
// the project.
export { sdk };

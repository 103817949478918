import Logger from 'helpers/logger';
import { EVENT_CATEGORY } from 'hooks';

const facetsVariantsAttributesKeyMap: Record<string, string> = {
  colourFamilies: 'color',
  inseam: 'inseam',
  size: 'size',
  franchise: 'collection',
};

const sortAttributesKeyMap: Record<string, string> = {
  'name:asc': 'Alphabetical (A-Z)',
  'name:desc': 'Alphabetical (Z-A)',
  'createdAt:desc': 'New Arrivals',
};

// General payload builder for Google Analytics events
export const buildGAPayload = (eventCategory: EVENT_CATEGORY, urlParams: URLSearchParams) => {
  let payload = {};

  switch (eventCategory) {
    case EVENT_CATEGORY.PAGE_VIEW:
      payload = buildPageViewPayloadFromURLParams(urlParams);
      break;

    // Add more cases here for other event categories as needed

    default:
      Logger.warn(`Unknown GA event category: ${eventCategory}`);
  }

  return payload;
};

const extractSortBy = (urlParams: URLSearchParams): string => {
  let sortBy = '';
  urlParams.forEach((value, key) => {
    if (key.startsWith('sortAttributes[')) {
      const sortKeyMatch = key.match(/sortAttributes\[\d+\]\[(.*?)\]/);

      if (sortKeyMatch && sortKeyMatch[1]) {
        const sortType = sortKeyMatch[1];
        const sortMapKey = `${sortType}:${value}`;
        sortBy = sortAttributesKeyMap[sortMapKey] || '';
      }
    }
  });
  return sortBy;
};

const extractAppliedFilters = (urlParams: URLSearchParams): string[] => {
  const appliedFilters: string[] = [];
  urlParams.forEach((value, key) => {
    if (key.startsWith('facets[')) {
      const filterKeyMatch = key.match(/facets\[variants\.attributes\.(.*?)\]\[terms\]/);

      if (filterKeyMatch && filterKeyMatch[1]) {
        const filterType = filterKeyMatch[1];
        const mappedFilterType = facetsVariantsAttributesKeyMap[filterType] || filterType;
        appliedFilters.push(`${mappedFilterType}:${value}`);
      }
    }
  });
  return appliedFilters;
};

// Helper function for PAGE_VIEW event with url params payload
const buildPageViewPayloadFromURLParams = (urlParams: URLSearchParams) => {
  const sortBy = extractSortBy(urlParams);
  const appliedFilters = extractAppliedFilters(urlParams);

  return {
    screen: {
      attributes: {
        gender: '',
        pageType: '',
        primaryCategory: '',
        subCategory1: '',
        categoryName: '',
        sortBy: sortBy || '',
        filter: {
          appliedFilters: appliedFilters.length > 0 ? appliedFilters : '',
        },
      },
    },
  };
};

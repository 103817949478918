import { useMemo } from 'react';
import { sdk } from 'sdk';
import { AccountData } from 'shared/types/businessUnit';
import useSWR from 'swr';

export const useAccountDataFetcher = (): AccountData => {
  const extensions = sdk.composableCommerce;
  const { data, error, isLoading } = useSWR('/action/account/getAccount', () => extensions.account.getAccount(), {
    revalidateOnMount: true, // Always fetch on mount
    revalidateOnFocus: true, // Do not refetch when tab is focused
  });

  return useMemo(() => {
    const response: AccountData = {
      account: undefined,
      loggedIn: false,
      accountLoading: isLoading,
      role: { key: '', name: '' },
      permissions: [],
      error,
    };

    if (error) {
      return response;
    }

    if (!data?.isError && data?.data?.loggedIn) {
      const account = data.data.account;
      const role = account?.role || { key: '', name: '' };
      const permissions = account?.permissions || [];

      return {
        account,
        loggedIn: !!account?.id,
        accountLoading: isLoading,
        role,
        permissions,
        error: undefined,
      };
    }

    return response;
  }, [data, error]);
};
